import { makeFilterableTree } from '../../utils/tree-node';

export function makeFilterMatcher(filter) {
  const filterWords = filter.split(' ');

  const matcher = node =>
    filterWords.some(filterWord => {
      const wordRegExp = new RegExp(filterWord, 'i');
      return wordRegExp.test(node.name);
    });

  return matcher;
}

export function makeInBasketMatcher(productsInBasket) {
  const matcher = node => productsInBasket.includes(node.id);
  return matcher;
}

export function updateMatchingNavigationTree(tree, matcher) {
  return makeFilterableTree(tree, matcher);
}
