import actions from './actions';
import { useFetchJsonOnce } from '../resource/hooks';
import resources from '../resource/resources';

export function useDocuments() {
  return useFetchJsonOnce(
    resources.DocumentData,
    './api/document',
    actions.DOCUMENTS_LOAD
  );
}
