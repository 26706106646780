export const shouldBeInTree = (node, matcher) => {
  if (
    node.children &&
    node.children.some(childNode => shouldBeInTree(childNode, matcher))
  ) {
    return true;
  }

  return matcher(node);
};

export class TreeNodeToggleableProxy {
  isOpen = false;
  proxiedChildren = null;

  constructor() {
    this.isOpen = false;
  }

  get = function(obj, prop) {
    if (prop === 'toggled') {
      return this.isOpen;
    }

    if (prop === 'children' && obj.children) {
      if (!this.proxiedChildren) {
        this.proxiedChildren = makeToggleableTree(obj.children);
      }

      return this.proxiedChildren;
    }

    return obj[prop];
  };

  deleteProperty = function(obj, prop) {
    if (prop === 'children') {
      this.proxiedChildren = null;
    }

    return delete obj[prop];
  };

  set = function(obj, prop, value) {
    if (prop === 'toggled') {
      this.isOpen = value;
    } else {
      obj[prop] = value;
    }

    return true;
  };
}

export class TreeNodeFilterableProxy {
  constructor(matcher) {
    this.matcher = matcher;
  }

  proxiedChildren = null;

  get = function(obj, prop) {
    if (prop === 'children' && obj.children) {
      if (!this.proxiedChildren) {
        this.proxiedChildren = obj.children.map(
          node => new Proxy(node, new TreeNodeFilterableProxy(this.matcher))
        );
      }
      return this.proxiedChildren.filter(node =>
        shouldBeInTree(node, this.matcher)
      );
    }

    return obj[prop];
  };
}

export function makeToggleableTree(treeData) {
  return treeData.map(node => new Proxy(node, new TreeNodeToggleableProxy()));
}

export function makeFilterableTree(treeData, matcher) {
  return treeData
    .map(node => new Proxy(node, new TreeNodeFilterableProxy(matcher)))
    .filter(node => shouldBeInTree(node, matcher));
}
