import actions from './actions';
import reducer from './reducer';

export default {
  actions,
  initialState: {
    basketTree: [],
    navigatorTree: [],
  },
  reducer,
};
