import actions from './actions';
import status from './status';

export default (state, action) => {
  switch (action.type) {
    case actions.RESOURCE_REQUEST: {
      return {
        ...state,
        [action.resource]: {
          status: status.IN_PROGRESS,
          ready: false,
          error: null,
        },
      };
    }

    case actions.RESOURCE_SUCCEED: {
      return {
        ...state,
        [action.resource]: {
          status: status.SUCCEEDED,
          ready: true,
          error: null,
        },
      };
    }

    case actions.RESOURCE_FAIL: {
      return {
        ...state,
        [action.resource]: {
          status: status.FAILED,
          ready: false,
          error: action.error.toString(),
        },
      };
    }

    case actions.RESOURCE_RESET: {
      return {
        ...state,
        [action.resource]: { status: status.IDLE, ready: false, error: null },
      };
    }

    default:
      return state;
  }
};
