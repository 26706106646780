import React from 'react';

import { themedColor } from '../../utils/string';

const RequirementIcon = ({ requirement }) => {
  let description;

  switch (requirement.relationship) {
    case 'M':
      description = 'Mandatory';
      break;
    case 'C':
      description = 'Conditional';
      break;
    case 'NR':
      description = 'Not Required';
      break;
    case 'NS':
      description = 'Not in Scope';
      break;
    default:
      description = '';
  }

  return (
    <div
      title={description}
      className="icon-rounded"
      style={{ backgroundColor: themedColor(requirement.relationship) }}
    >
      {requirement.relationship}
    </div>
  );
};

export default RequirementIcon;
