import { useHistory } from 'react-router-dom';
import React from 'react';

import Treeview from '../treeview';

import { useNavigation } from '../../state/navigation/hooks';
import { routeWithParams } from '../../utils/string';
import routes from '../../routes';
import resourceStatus from '../../state/resource/status';
import { useBasket } from '../../state/basket/hooks';
import { useExport } from '../../state/export/hooks';

const NavBasket = () => {
  const { navigation, toggleBasketNode } = useNavigation();
  const { clearBasket, hasProducts, isInBasket } = useBasket();
  const history = useHistory();
  const { status: fileGenerationStatus, generateFile } = useExport();

  const toggleAndNavigate = (node, toggled) => {
    toggleBasketNode(node, toggled);
    if (node.type !== 'TOPNODE' && isInBasket(node)) {
      history.push(
        routeWithParams(routes.basketProduct, { productId: node.id })
      );
    }
  };

  return (
    <React.Fragment>
      <div className="layout__sidebar-head">
        <div className="lineup">
          <button
            className="btn btn-primary"
            onClick={generateFile}
            disabled={
              !hasProducts ||
              fileGenerationStatus === resourceStatus.IN_PROGRESS
            }
          >
            Export
          </button>
          <button
            className="btn btn-primary"
            onClick={clearBasket}
            disabled={!hasProducts}
          >
            Clear basket
          </button>
        </div>
      </div>
      <div className="layout__sidebar-body">
        <Treeview data={navigation.basketTree} onToggle={toggleAndNavigate} />
      </div>
    </React.Fragment>
  );
};

export default NavBasket;
