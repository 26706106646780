import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Redirect, useRouteMatch } from 'react-router-dom';
import React from 'react';

import { useStateValue } from '../../state';

import { routeWithParams } from '../../utils/string';
import { useBasket } from '../../state/basket/hooks';
import Document from '../document';
import { useDocuments } from '../../state/documents/hooks';
import { useProducts } from '../../state/products/hooks';
import ProductSection from '../product-section';
import { useApp } from '../../state/app/hooks';
import { useEnvironment } from '../../state/environment/hooks';

const Product = ({ baseUrl, documentUrl, isBasketPage }) => {
  const { isProductDetailsOpen, toggleProductDetails } = useApp();
  const productsState = useProducts();
  const documentsState = useDocuments();
  const [{ products }] = useStateValue();
  const productRouteMatch = useRouteMatch(baseUrl);
  const product = products[productRouteMatch.params.productId];
  const { handleToggleInBasket, getToggleLabel, isInBasket } = useBasket();
  const { environmentResourceState, allowsBasket } = useEnvironment();

  let didNotFindDocument = false;

  if (!environmentResourceState.ready) {
    return <div>Loading environment… {environmentResourceState.error}</div>;
  }

  if (!productsState.ready) {
    return <div>Loading products… {productsState.error}</div>;
  }

  if (!documentsState.ready) {
    return <div>Loading documents… {documentsState.error}</div>;
  }

  if (!product) {
    return <div>No product found</div>;
  }

  if (isBasketPage && !isInBasket(product)) {
    return <div>Product is not in basket</div>;
  }

  if (productRouteMatch.isExact) {
    didNotFindDocument = true;
    const defaultRequirementGroup = product.requirement_groups[0];

    if (defaultRequirementGroup) {
      const defaultDocument = product.requirement_groups[0].requirements[0];

      if (defaultDocument) {
        return (
          <Redirect
            to={routeWithParams(documentUrl, {
              productId: productRouteMatch.params.productId,
              documentId: defaultDocument.id,
            })}
          />
        );
      }
    }
  }

  return (
    <React.Fragment>
      <div className="layout__content-product">
        <div className="layout__content-top">
          <span
            className="layout__content-top-req-h3 show-as-clickable"
            title={product.name}
            onClick={toggleProductDetails}
          >
            <FontAwesomeIcon icon={faChevronCircleRight} /> {product.name}
          </span>
          {allowsBasket && (
            <span className="layout__content-top-right">
              <div>
                {product.addable && (
                  <button
                    className="btn btn-primary"
                    onClick={() => handleToggleInBasket(product)}
                  >
                    {getToggleLabel(product)}
                  </button>
                )}
                {!product.addable && (
                  <div className="alert">
                    This product cannot be added to the basket
                  </div>
                )}
              </div>
            </span>
          )}
        </div>

        {isProductDetailsOpen && (
          <div className="layout__content-textbox-large mt-5 darkgrey-bg" style={{ height: '22%' }}>
            <h4 className="dark underline">Product Version and Information</h4>
            <div
              dangerouslySetInnerHTML={{ __html: product.html_description }}
            ></div>
          </div>
        )}

        <div className="layout__content-top-req-catlist">
          {product.requirement_groups.map(req_group => (
            <ProductSection
              documentUrl={documentUrl}
              isBasketPage={isBasketPage}
              product={product}
              req_group={req_group}
              key={req_group.name}
            />
          ))}
        </div>
      </div>

      {didNotFindDocument && 'No requirements found associated with product'}
      {!didNotFindDocument && <Document baseUrl={documentUrl} />}
    </React.Fragment>
  );
};

export default Product;
