import actions from './actions';
import appActions from '../app/actions';
import basketActions from '../basket/actions';
import {
  makeFilterMatcher,
  makeInBasketMatcher,
  updateMatchingNavigationTree,
} from './data';

import { makeToggleableTree } from '../../utils/tree-node';

let initialBasketTree;
let initialNavigatorTree;

export default (state, action, fullState) => {
  switch (action.type) {
    case actions.NAVIGATION_LOAD: {
      initialBasketTree = makeToggleableTree(action.data);
      initialNavigatorTree = makeToggleableTree(action.data);

      return {
        basketTree: updateMatchingNavigationTree(
          initialBasketTree,
          makeInBasketMatcher(fullState.basket.productsInBasket)
        ),
        navigatorTree: initialNavigatorTree,
      };
    }

    case actions.NAVIGATION_TOGGLE_BASKET_TREE_NODE:
    case actions.NAVIGATION_TOGGLE_NAVIGATOR_TREE_NODE: {
      const stateKey =
        action.type === actions.NAVIGATION_TOGGLE_BASKET_TREE_NODE
          ? 'basketTree'
          : 'navigatorTree';

      if (action.node.children) {
        action.node.toggled = action.toggled;
      }

      return {
        ...state,
        [stateKey]: [...state[stateKey]],
      };
    }

    case basketActions.BASKET_CHANGED: {
      const matcher = makeInBasketMatcher(fullState.basket.productsInBasket);

      return {
        ...state,
        basketTree: updateMatchingNavigationTree(initialBasketTree, matcher), // Use initial full data to apply filter to
      };
    }

    case appActions.APP_FILTER_CHANGE: {
      const matcher = makeFilterMatcher(action.filter);

      return {
        ...state,
        navigatorTree: updateMatchingNavigationTree(
          initialNavigatorTree, // Use initial full data to apply filter to
          matcher
        ),
      };
    }

    default:
      return state;
  }
};
