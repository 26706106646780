import actions from './actions';
import { useFetchJsonOnce } from '../resource/hooks';
import resources from '../resource/resources';

export function useProducts() {
  return useFetchJsonOnce(
    resources.ProductData,
    './api/product',
    actions.PRODUCTS_LOAD
  );
}
