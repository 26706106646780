import React from 'react';

const filenameMappings = {
  SYS: 'system',
  SSYS: 'sub-system',
  TSYS: 'TechnicalSystem',
  ASSY: 'assy',
  SASSY: 'sub-assy',
  P: 'product',
  FG: 'functional_group',
  PG: 'product_group',
  PC: 'product_w_comp',
  EQUI: 'equipment',
  COMP: 'component',
  SCOMP: 'sub_comp',
  Cpart: 'part',
  MAT: 'material',
  'Part of System Documentation': 'sub-system',
  'Part of Assembly Documentation': 'sub-assy',
  Function: 'asset_function',
  Location: 'asset_location',
  Product: 'product_any',
  'Product | Function': 'product_function',
  'Product | Location': 'product_location',
  'Product | Typical': 'product_typical',
  'Product | Individual': 'product_individual',
};

const Icon = React.memo(({ name, nonStandard }) => {
  if (name === undefined) {
    console.warn('Cannot find icon with no name provided');
    return null;
  }

  if (['SUBSEA', 'TOPNODE'].includes(name)) {
    return null;
  }

  var filename = filenameMappings[name];

  if (nonStandard === "Yes") {
    filename = "NS_" + filename
  }

  try {
    const image = require(`./images/${filename}.png`);
    return <img alt={name} className="category-items" src={image} />;
  } catch (e) {
    console.warn(`Could not find icon for "${name}"`);
    return null;
  }
});

export default Icon;
