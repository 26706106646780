export default {
  home: '/',

  navigator: '/navigator',
  navigatorProduct: '/navigator/:productId',
  navigatorDocument: '/navigator/:productId/:documentId',

  basket: '/basket',
  basketProduct: '/basket/:productId',
  basketDocument: '/basket/:productId/:documentId',
};
