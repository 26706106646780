import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Route, Redirect } from 'react-router-dom';

import { StateProvider, reducer, initialState } from './state';
import './index.css';
import routes from './routes';
import App from './components/app';

// import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <StateProvider initialState={initialState} reducer={reducer}>
    <HashRouter>
      <Route
        exact
        path={routes.home}
        render={() => <Redirect to={routes.navigator} />}
      />
      <App />
    </HashRouter>
  </StateProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
