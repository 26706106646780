import actions from './actions';

export const LOCAL_STORAGE_KEY = 'basket';

export default function basketEffects(action, state) {
  switch (action.type) {
    case actions.BASKET_ADD:
    case actions.BASKET_CLEAR:
    case actions.BASKET_REMOVE: {
      localStorage.setItem(
        LOCAL_STORAGE_KEY,
        JSON.stringify(state.basket.productsInBasket)
      );
      return;
    }

    default:
      return;
  }
}
