import actions from './actions';
import { useStateValue } from '..';
import { useFetchJsonOnce } from '../resource/hooks';
import resources from '../resource/resources';

export function useEnvironment() {
  const [{ environment }] = useStateValue();

  const environmentResourceState = useFetchJsonOnce(
    resources.Environment,
    './api/environment',
    actions.ENVIRONMENT_LOAD
  );

  // const allowsBasket = environment.TIRC_Site === 'Z-018';
  const allowsBasket = true;

  const isTest = environment.TIRC_Env === 'Development';

  return {
    environmentResourceState,
    environment,
    allowsBasket,
    isTest,
  };
}
