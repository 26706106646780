import { useState, useEffect } from 'react';
import { useStateValue } from '..';
import { useResource } from '../resource/hooks';
import resources from '../resource/resources';
import resourceStatus from '../resource/status';
import { downloadBlob } from '../../utils/file';
import { getFullTimestamp } from '../../utils/date';

export function useExport() {
  const [trigger, setTrigger] = useState(false);
  const [{ basket, exclusions }] = useStateValue();
  const { status, request, succeed, fail } = useResource(resources.Export);

  const exportProducts = {};

  basket.productsInBasket.forEach(productId => {
    const productExclusions = exclusions[productId];
    exportProducts[productId] = {
      id: productId,
      exclusions: productExclusions,
    };
  });

  const generateFile = () => setTrigger(true);

  useEffect(() => {
    if (trigger && status !== resourceStatus.IN_PROGRESS) {
      setTrigger(false);

      const post = async () => {
        const body = JSON.stringify(exportProducts);
        const headers = { 'Content-type': 'application/json' };

        request();

        try {
          const response = await fetch('./api/export', {
            method: 'post',
            body,
            headers,
          });
          const file = await response.blob();
          downloadBlob(file, `TIRC export ${getFullTimestamp()}.xlsx`);
          succeed();
        } catch (e) {
          fail(e);
        }
      };

      post();
    }
  }, [exportProducts, fail, request, setTrigger, status, succeed, trigger]);

  return { status, generateFile };
}
