import { useStateValue } from '..';
import actions from './actions';

export function useApp() {
  const [state, dispatch] = useStateValue();

  const changeFilter = filter =>
    dispatch({ type: actions.APP_FILTER_CHANGE, filter });

  const clearFilter = () =>
    dispatch({ type: actions.APP_FILTER_CHANGE, filter: '' });

  const toggleProductDetails = () =>
    dispatch({ type: actions.APP_TOGGLE_PRODUCT_DETAILS });

  const toggleProductSection = section =>
    dispatch({ type: actions.APP_TOGGLE_PRODUCT_SECTION, section });

  const isSectionOpen = section =>
    !state.app.productSectionsClosed.includes(section);

  return {
    isProductDetailsOpen: !state.app.productDetailsClosed,
    isSectionOpen,
    changeFilter,
    clearFilter,
    toggleProductDetails,
    toggleProductSection,
  };
}
