import React from 'react';
import { useHistory } from 'react-router-dom';

import Treeview from '../treeview';

import { useNavigation } from '../../state/navigation/hooks';
import { routeWithParams } from '../../utils/string';
import routes from '../../routes';

import Filter from '../filter';

const NavNavigator = () => {
  const history = useHistory();
  const {
    navigationResourceState,
    navigation,
    toggleNavigatorNode,
  } = useNavigation();

  if (!navigationResourceState.ready) {
    return <div>Loading… {navigationResourceState.error}</div>;
  }

  const toggleAndNavigate = (node, toggled) => {
    toggleNavigatorNode(node, toggled);

    if (node.type !== 'TOPNODE') {
      history.push(
        routeWithParams(routes.navigatorProduct, { productId: node.id })
      );
    }
  };

  return (
    <React.Fragment>
      <div className="layout__sidebar-head">
        <Filter />
      </div>
      <div className="layout__sidebar-body">
        <Treeview
          data={navigation.navigatorTree}
          onToggle={toggleAndNavigate}
        />
      </div>
    </React.Fragment>
  );
};

export default NavNavigator;
