import React from 'react';
import { routeWithParams } from '../../utils/string';
import Icon from '../icon';
import { NavLink } from 'react-router-dom';
import { useStateValue } from '../../state';
import { useExclusions } from '../../state/exclusions/hooks';
import RequirementIcon from '../requirement-icon';
import { useApp } from '../../state/app/hooks';

const isRequirmentConditional = req => req.relationship === 'C';

export const ProductSection = ({
  product,
  req_group,
  documentUrl,
  isBasketPage,
}) => {
  const [{ documents }] = useStateValue();
  const { handleToggleExclusion, isExcluded } = useExclusions();
  const { isSectionOpen, toggleProductSection } = useApp();

  return (
    <div key={req_group.name}>
      <button
        className="btn btn-link"
        onClick={() => toggleProductSection(req_group.name)}
      >
        <div className="category-title">
          <span className="toggler category-items">-</span>
          <span>
            {req_group.name} ({req_group.requirements.length})
          </span>
        </div>
      </button>
      {isSectionOpen(req_group.name) && (
        <ul className="main-content-list">
          {req_group.requirements.map(req => (
            <li key={req.id}>
              <div className="req-list-item">
                <div className="req-list-item__pds-icon">
                  <Icon name={req_group.name} nonStandard={req.non_std_pds}></Icon>
                </div>
                <NavLink
                  className="req-list-item__link"
                  title={req.name}
                  to={routeWithParams(documentUrl, {
                    productId: product.id,
                    documentId: req.id,
                  })}
                >
                  {documents[req.id].name}
                </NavLink>
                {isBasketPage && isRequirmentConditional(req) && (
                  <label className="include-toggle">
                    <input
                      checked={!isExcluded(product.id, req.id)}
                      onChange={() => handleToggleExclusion(product.id, req.id)}
                      type="checkbox"
                    />
                    <span className="include-toggle__text">include</span>
                  </label>
                )}
                <div className="req-list-item__icons">
                  <RequirementIcon requirement={req} />
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ProductSection;
