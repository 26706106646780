import { LOCAL_STORAGE_KEY } from './effects';

let exclusions = {};

try {
  exclusions = localStorage.getItem(LOCAL_STORAGE_KEY) || {};
} catch (e) {
  console.error(e);
}

export default exclusions;
