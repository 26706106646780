import { useStateValue } from '..';
import actions from './actions';
import status from './status';
import { useEffect } from 'react';

export function useResource(resource) {
  const [{ resource: resourceState }, dispatch] = useStateValue();

  const request = () => dispatch({ type: actions.RESOURCE_REQUEST, resource });

  const succeed = () => dispatch({ type: actions.RESOURCE_SUCCEED, resource });

  const fail = error =>
    dispatch({ type: actions.RESOURCE_FAIL, resource, error });

  const reset = () => dispatch({ type: actions.RESOURCE_RESET, resource });

  return {
    status: resourceState[resource].status,
    request,
    succeed,
    fail,
    reset,
  };
}

export function useFetchJsonOnce(resource, url, onSuccessAction) {
  const { request, succeed, fail } = useResource(resource);
  const [{ resource: resourceState }, dispatch] = useStateValue();

  // There seems to be a race condition here; if multiple components call `useFetchJsonOnce`
  // quickly enough the store state doesn't seem to update for the resource

  useEffect(() => {
    if (resourceState[resource].status === status.IDLE) {
      request();

      fetch(url)
        .then(response => response.json())
        .then(data => {
          dispatch({ type: onSuccessAction, data });
          succeed();
        })
        .catch(err => fail(err));
    }
  }, [
    dispatch,
    fail,
    onSuccessAction,
    request,
    resource,
    resourceState,
    succeed,
    url,
  ]);

  return resourceState[resource];
}
