import actions from './actions';

export default (state, action) => {
  switch (action.type) {
    case actions.BASKET_ADD: {
      const updatedProductsInBasket = new Set(state.productsInBasket);
      action.productIds.forEach(element => updatedProductsInBasket.add(element));
      return {
        ...state,
        productsInBasket: [...updatedProductsInBasket],
      };
    }

    case actions.BASKET_REMOVE: {
      const updatedProductsInBasket = new Set(state.productsInBasket);
      action.productIds.forEach(element => updatedProductsInBasket.delete(element));
      return {
        ...state,
        productsInBasket: [...updatedProductsInBasket],
      };
    }

    case actions.BASKET_CLEAR: {
      return {
        ...state,
        productsInBasket: [],
      };
    }

    default:
      return state;
  }
};
