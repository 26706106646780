import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { useStateValue } from '../../state';

const DocumentBox = ({ className, content, title }) => {
  return (
    <div className={className}>
      <h4 className="dark underline">{title}</h4>
      <div>{addLineBreak(content)}</div>
    </div>
  );
};

//CONTAINS INLINE STYLING
const addLineBreak = definition => {
  if (!definition) {
    return null;
  }
  let splitDef = definition.split('\r\n');
  return (
    <ul>
      {splitDef
        .filter(n => !!n.trim())
        .map((n, idx) => (
          <li key={idx} style={{ listStyle: 'none' }}>
            {n}
          </li>
        ))}
    </ul>
  );
};

const Document = ({ baseUrl }) => {
  const [{ documents, products }] = useStateValue();
  const documentRouteMatch = useRouteMatch(baseUrl);
  const document = documents[documentRouteMatch.params.documentId];
  const product = products[documentRouteMatch.params.productId];

  if (!document) {
    return <div>No document found</div>;
  }

  let requirement;

  for (const group of product.requirement_groups) {
    requirement = group.requirements.find(req => req.id === document.id);
    if (requirement) {
      break;
    }
  }

  return (
    <div className="layout__content-requirement">
      <div>
        <span className="layout__content-top-doc-h3" title={document.name}>
          {document.name.toUpperCase()}
        </span>
      </div>
      <div className="layout__content-textbox-section-info">
        <h4 className="dark underline">Document Version and Information</h4>
        <div
          dangerouslySetInnerHTML={{ __html: document.doc_html_desc }}
        ></div>
      </div>
      <DocumentBox
        title="Document Function Definition"
        className="layout__content-textbox"
        content={document.doc_functional_definition}
      ></DocumentBox>
      <DocumentBox
        title="Document Content Definition"
        className="layout__content-textbox-small"
        content={document.doc_content_definition}
      ></DocumentBox>
      <DocumentBox
        title="Project Phase Justification"
        className="layout__content-textbox-small"
        content={document.phase_project_justification}
      ></DocumentBox>
      <DocumentBox
        title="Operation Phase Justification"
        className="layout__content-textbox-small"
        content={document.phase_operation_justification}
      ></DocumentBox>
      <div className="layout__content-textbox-section-info">
        <h4 className="dark underline">Requirement Version and Information</h4>
        <div
          dangerouslySetInnerHTML={{ __html: requirement.req_html_desc }}
        ></div>
      </div>
      <DocumentBox
        title="Requirement Condition"
        className="layout__content-textbox-small"
        content={requirement.condition}
      ></DocumentBox>
      <DocumentBox
        title="Requirement Details"
        className="layout__content-textbox-small"
        content={requirement.details}
      ></DocumentBox>
      <DocumentBox
        title="Additional Details"
        className="layout__content-textbox-small"
        content={document.additional_details}
      ></DocumentBox>
    </div>
  );
};

export default Document;
