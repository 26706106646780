import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { useApp } from '../../state/app/hooks';
import { useStateValue } from '../../state';

// If at least one word is less than the required length, give a warning
const isFilterInvalid = filter =>
  filter.length > 0 && filter.split(' ').some(w => w.length < 2);

const Filter = () => {
  const [{ app }] = useStateValue();
  const { changeFilter, clearFilter } = useApp();

  return (
    <div className="filter">
      <div className="search-container">
        <div className="search-icon">
          <FontAwesomeIcon icon={faSearch} color="grey" />
        </div>

        <div className="search-box">
          <input
            className="search-area"
            onChange={ev => changeFilter(ev.target.value)}
            type="search"
            value={app.filter}
            placeholder="Filter…"
          />
        </div>
      </div>

      {isFilterInvalid(app.filter) && (
        <div className="alert">
          <p className="brown">Filter words require at least two characters</p>
        </div>
      )}
      {app.filter && (
        <button className="btn btn-primary" onClick={clearFilter}>
          Clear
        </button>
      )}
    </div>
  );
};

export default Filter;
