//style treebeard here. this style is called in <treebeard styles={STYLE_HERE} />
export default {
  tree: {
    base: {
      listStyle: 'none',
      backgroundColor: 'none',
      margin: 0,
      padding: 0,
      color: '#276192',
      fontFamily: "Lato, 'sans-serif",
      fontSize: '1em',
    },
    node: {
      base: {
        position: 'relative',
        color: '#276192',
      },
      link: {
        alignItems: 'center',
        background: 'white',
        border: '1px solid #DCE0E6',
        borderRadius: '.25rem',
        cursor: 'pointer',
        display: 'flex',
        padding: '1px 4px 1px 4px',
        position: 'relative',
        width: '100%',
      },
      activeLink: {
        color: '#f8b34a',
      },
      toggle: {
        base: {
          position: 'relative',
          display: 'inline-block',
          verticalAlign: 'top',
          marginLeft: '-5px',
          height: '24px',
          width: '24px',
        },
        wrapper: {
          position: 'absolute',
          top: '50%',
          left: '50%',
          margin: '-7px 0 0 -7px',
          height: '14px',
        },
        height: 14,
        width: 14,
        arrow: {
          fill: '#9DA5AB',
          strokeWidth: 0,
        },
      },
      header: {
        base: {
          flex: '1 1 auto',
          overflow: 'hidden',
        },
        connector: {
          width: '2px',
          height: '12px',
          borderLeft: 'solid 2px black',
          borderBottom: 'solid 2px black',
          position: 'absolute',
          top: '0px',
          left: '-21px',
        },
        title: {},
      },
      subtree: {
        listStyle: 'none',
        paddingLeft: '19px',
      },
      loading: {
        color: '#276192',
      },
    },
  },
};
