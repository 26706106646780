import actions from './actions';

export default (state, action) => {
  switch (action.type) {
    case actions.PRODUCTS_LOAD: {
      return action.data;
    }

    default:
      return state;
  }
};
