export function getFullTimestamp(d = new Date()) {
  const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
  const month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
  const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
  const hour = new Intl.DateTimeFormat('en', {
    hour: '2-digit',
    hourCycle: 'h24',
  }).format(d);
  const minute = new Intl.DateTimeFormat('en', { minute: '2-digit' })
    .format(d)
    .padStart(2, '0'); // Chrome bug; does not pad to 2 digits
  const second = new Intl.DateTimeFormat('en', { second: '2-digit' })
    .format(d)
    .padStart(2, '0'); // Chrome bug; does not pad to 2 digits;

  return `${year}-${month}-${day} ${hour}-${minute}-${second}`;
}
