import actions from './actions';

export default (state, action) => {
  switch (action.type) {
    case actions.EXCLUSIONS_ADD: {
      const productExclusions = new Set(state[action.productId] || []);
      productExclusions.add(action.documentId);
      return { ...state, [action.productId]: [...productExclusions] };
    }
    case actions.EXCLUSIONS_REMOVE: {
      const productExclusions = new Set(state[action.productId] || []);
      productExclusions.delete(action.documentId);
      return { ...state, [action.productId]: [...productExclusions] };
    }
    default:
      return state;
  }
};
