import React from 'react';
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Treebeard, decorators } from 'react-treebeard';

import Icon from '../icon';

import treeViewStyles from './treeViewStyles';

const customDecorators = {
  ...decorators,

  Header: props => (
    <div className="tree-header">
      <div className="tree-header-icon">
        <Icon name={props.node.type} nonStandard={props.node.non_std_n} />
      </div>
      <div>{props.node.name}</div>
    </div>
  ),

  Toggle: () => {
    return (
      <div className="tree-expand-toggler">
        <FontAwesomeIcon icon={faChevronCircleRight} size="1x" />
      </div>
    );
  },
};

export default ({ data, onToggle }) => {
  return (
    <Treebeard
      data={data}
      onToggle={onToggle}
      decorators={customDecorators}
      style={treeViewStyles}
    />
  );
};
