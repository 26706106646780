import React from 'react';
import { Route, Switch } from 'react-router-dom';
import routes from '../../routes';

import AppHeader from '../app-header';
import FrontEmptystate from '../front-page';
import NavBasket from '../nav-basket';
import NavNavigator from '../nav-navigator';
import Product from '../product';
import { useEnvironment } from '../../state/environment/hooks';

const App = () => {
  const { environmentResourceState, isTest } = useEnvironment();

  if (!environmentResourceState.ready) {
    return <div>Loading… {environmentResourceState.error}</div>;
  }

  return (
    <div className={`layout__root ${isTest ? 'env-test' : ''}`}>
      <div className="layout__head" id="navigation">
        <AppHeader />
      </div>

      <div className="layout__body">
        <div className="layout__sidebar col-3">
          <Switch>
            <Route path={routes.navigator}>
              <NavNavigator />
            </Route>
            <Route path={routes.basket}>
              <NavBasket />
            </Route>
          </Switch>
        </div>

        <div className="layout__content col-9 grey-bg">
          <Switch>
            <Route path={routes.navigator} exact>
              <FrontEmptystate />
            </Route>
            <Route path={routes.basket} exact>
              <FrontEmptystate />
            </Route>
            <Route path={routes.navigator}>
              <Product
                baseUrl={routes.navigatorProduct}
                documentUrl={routes.navigatorDocument}
              />
            </Route>
            <Route path={routes.basket}>
              <Product
                baseUrl={routes.basketProduct}
                documentUrl={routes.basketDocument}
                isBasketPage
              />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default App;
