import React from 'react';
// import webTextData from '../../data/webtext.json';
import { useEnvironment } from '../../state/environment/hooks';
import splashImage from './splash.png';

const FrontPage = () => {
  const { environmentResourceState, environment } = useEnvironment();

  if (!environmentResourceState.ready) {
    return <div>Loading… {environmentResourceState.error}</div>;
  }

  const headline = environment.siteTokens.frontpageHeadline;
  const textMiddle = environment.siteTokens.frontpageBody;
  const textBottom = environment.siteTokens.frontpageFooter;

  return (
    <React.Fragment>
      <div className="front-emptystate">
        <div dangerouslySetInnerHTML={{ __html: headline }}></div>

        <div>
          <img
            src={splashImage}
            alt="TIRC splashscreen"
            height="auto"
            width="593px"
          />
        </div>

        <div dangerouslySetInnerHTML={{ __html: textMiddle }}></div>
        <div dangerouslySetInnerHTML={{ __html: textBottom }}></div>
      </div>
    </React.Fragment>
  );
};

export default FrontPage;

// let headline = webTextData[0].WTEXT;
// let textMiddle = webTextData[1].WTEXT;
// let textBottom = webTextData[2].WTEXT;
// let image = require('../images/epim_tirc_sti_logo4-4-NorskStandard.png');

// const setEnvironment = () => {
//   switch (appEnvironment) {
//     case 'sti':
//       image = require('../images/epim_tirc_sti_logo4-4.png');
//       break;

//     case 'sti-test':
//       image = require('../images/epim_tirc_sti_logo4-4.png');
//       break;

//     case 'z018':
//       image = require('../images/epim_tirc_sti_logo4-4-NorskStandard.png');
//       break;

//     case 'z018-test':
//       image = require('../images/epim_tirc_sti_logo4-4-NorskStandard.png');
//       break;
//     default:
//   }
// };
