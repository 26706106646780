import React, { createContext, useContext, useReducer } from 'react';

import app from './app';
import basket from './basket';
import documents from './documents';
import environment from './environment';
import exclusions from './exclusions';
import navigation from './navigation';
import products from './products';
import resource from './resource';

import middleware from './middleware';

// see https://medium.com/simply/state-management-with-react-hooks-and-context-api-at-10-lines-of-code-baf6be8302c

export const StateContext = createContext();

export const StateProvider = ({ reducer, initialState, children }) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
);

export const useStateValue = () => useContext(StateContext);

const stateModules = {
  app,
  basket,
  documents,
  environment,
  exclusions,
  navigation,
  products,
  resource,
};

export const initialState = {};

Object.keys(stateModules).forEach(moduleName => {
  initialState[moduleName] = stateModules[moduleName].initialState;
});

export const reducer = (state, action) => {
  const newState = {};

  Object.keys(stateModules).forEach(moduleName => {
    const moduleState = state[moduleName];
    newState[moduleName] = stateModules[moduleName].reducer(
      moduleState,
      action,
      state
    );
  });

  console.log('ACTION', action, { state, newState });

  // TODO: it would be nice to have dispatch() available from the MW, like Sagas do

  middleware.forEach(cb => cb(action, newState, state));

  return newState;
};
