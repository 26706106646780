import { useStateValue } from '..';
import actions from './actions';

export function useBasket() {
  const [{ basket }, dispatch] = useStateValue();

  const isInBasket = product => basket.productsInBasket.includes(product.id);

  const getToggleLabel = product => (isInBasket(product) ? 'Remove From Basket' : 'Add To Basket');

  const handleToggleInBasket = product => {
    if (basket.productsInBasket.includes(product.id)) {
      dispatch({ type: actions.BASKET_REMOVE, productIds: product.ids_to_add, basket });
    } else {
      dispatch({ type: actions.BASKET_ADD, productIds: product.ids_to_add, basket });
    }

    dispatch({ type: actions.BASKET_CHANGED });
  };

  const handleToggleNode = (node, toggled) => {
    dispatch({
      type: actions.BASKET_TOGGLE_TREE_NODE,
      node,
      toggled,
    });
  };

  const clearBasket = () => {
    dispatch({ type: actions.BASKET_CLEAR });
    dispatch({ type: actions.BASKET_CHANGED });
  };

  const hasProducts = basket.productsInBasket.length > 0;

  return {
    clearBasket,
    getToggleLabel,
    handleToggleInBasket,
    handleToggleNode,
    hasProducts,
    isInBasket,
  };
}
