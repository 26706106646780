import status from './status';
import resources from './resources';

const state = {};

Object.keys(resources).forEach(resource => {
  state[resource] = { status: status.IDLE, error: null };
});

export default Object.freeze(state);
