import { LOCAL_STORAGE_KEY } from './effects';

let productsInBasket = [];

try {
  const fromStorage = localStorage.getItem(LOCAL_STORAGE_KEY);

  if (fromStorage) {
    const products = JSON.parse(fromStorage);
    productsInBasket = [...new Set(products)];
  }
} catch (e) {
  console.error(e);
}

export default {
  productsInBasket,
};
