import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import routes from '../../routes';
import { useStateValue } from '../../state';
import { useEnvironment } from '../../state/environment/hooks';

import logoImage from './logo.png';
import standardImage from './standard.png';

const AppHeader = () => {
  const [{ basket }] = useStateValue();
  const {
    environmentResourceState,
    environment,
    allowsBasket,
  } = useEnvironment();

  if (!environmentResourceState.ready) {
    return <div>Loading… {environmentResourceState.error}</div>;
  }

  return (
    <React.Fragment>
      <Link to={routes.home} id="nav-button">
        <div id="logo">
          <img src={logoImage} alt="TIRC logo" height="12" width="auto" />
        </div>
      </Link>
      <div id="menu-top">
        <ul>
          <li>
            <NavLink to={routes.navigator} className="nav-link">
              Navigator
            </NavLink>
          </li>
          {allowsBasket && (
            <li>
              <NavLink to={routes.basket} className="nav-link">
                Basket
                <div className="icon-rounded">
                  {basket.productsInBasket.length}
                </div>
              </NavLink>
            </li>
          )}
          {environment.TIRC_Site === 'Z-018' && (
            <li>
              <a
                className="standardImageWrapper"
                href="https://www.standard.no/en/sectors/energi-og-klima/petroleum"
              >
                <img
                  className="standardImage"
                  alt="Standard Norge"
                  title="Standard Norge"
                  src={standardImage}
                />
              </a>
            </li>
          )}
          <li className="right-aligned-text">
            <span>Technical Information Requirement Catalogue</span>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default AppHeader;
