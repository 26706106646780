import actions from './actions';

export default (state, action) => {
  switch (action.type) {
    case actions.APP_FILTER_CHANGE: {
      return {
        ...state,
        filter: action.filter,
      };
    }

    case actions.APP_TOGGLE_PRODUCT_DETAILS: {
      return {
        ...state,
        productDetailsClosed: !state.productDetailsClosed,
      };
    }

    case actions.APP_TOGGLE_PRODUCT_SECTION: {
      const closedSections = new Set(state.productSectionsClosed);

      if (closedSections.has(action.section)) {
        closedSections.delete(action.section);
      } else {
        closedSections.add(action.section);
      }

      return {
        ...state,
        productSectionsClosed: [...closedSections],
      };
    }

    default:
      return state;
  }
};
