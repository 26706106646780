import ColorHash from 'color-hash';

export const routeWithParams = (route, params) =>
  route.replace(/:(\w+)/g, (_, key) => encodeURIComponent(params[key]));

export const initialsOnly = str =>
  str
    .split(' ')
    .map(element => element[0])
    .join('');

export const themedColor = (() => {
  const colorHashThemedColor = new ColorHash({
    lightness: 0.5,
    saturation: 0.25,
  });

  return str => {
    if (str === 'M') {
      return '#000';
    }
    return colorHashThemedColor.hex(str + str + 'salt');
  };
})();
