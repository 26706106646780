import { useStateValue } from '..';
import actions from './actions';

export function useExclusions() {
  const [{ exclusions }, dispatch] = useStateValue();

  const handleToggleExclusion = (productId, documentId) => {
    if (isExcluded(productId, documentId)) {
      dispatch({ type: actions.EXCLUSIONS_REMOVE, productId, documentId });
    } else {
      dispatch({ type: actions.EXCLUSIONS_ADD, productId, documentId });
    }
  };

  const isExcluded = (productId, documentId) =>
    !!(exclusions[productId] && exclusions[productId].includes(documentId));

  return {
    handleToggleExclusion,
    isExcluded,
  };
}
