import actions from './actions';
import { useStateValue } from '..';
import { useFetchJsonOnce } from '../resource/hooks';
import resources from '../resource/resources';

export function useNavigation() {
  const [{ basket, navigation }, dispatch] = useStateValue();

  const toggleBasketNode = (node, toggled) =>
    dispatch({
      type: actions.NAVIGATION_TOGGLE_BASKET_TREE_NODE,
      node,
      toggled,
      basket,
    });

  const toggleNavigatorNode = (node, toggled) =>
    dispatch({
      type: actions.NAVIGATION_TOGGLE_NAVIGATOR_TREE_NODE,
      node,
      toggled,
    });

  const navigationResourceState = useFetchJsonOnce(
    resources.NavigationData,
    './api/navigation',
    actions.NAVIGATION_LOAD
  );

  return {
    navigationResourceState,
    navigation,
    toggleBasketNode,
    toggleNavigatorNode,
  };
}
